






















































































































































































































































































.ovy-a {
  display: flex;

  .ovy_l {
    width: 180px;
    overflow-y: auto;
    padding-right: 20px;
    cursor: pointer;

    .ovy_l_1 {
      background: #5C6BE8;
      color: #fff;
      text-align: center;
      width: 100px;
      font-size: 12px;
      line-height: 30px;
      border-radius: 4px;
      margin: auto;
    }

    .ovy_l_2 {
      width: 100%;
      border: 1px solid #2379ff;
      margin-top: 10px;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;

      .ovy_l_2_1 {
        width: 100%;
        text-align: center;
        line-height: 25px;
        color: #2379ff;
        background: #edf5ff;

      }

      .ovy_l_2_2 {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 2px 4px;
        height: 40px;
      }

      &:hover {
        .ovy_l_2_1 {
          color: #fff;
          background: #2379ff;
        }
      }
    }
  }

  .ovy_r {
    overflow-y: auto;
    flex: 1;
    padding: 0 10px 0 20px;

    .shell {
      .ovy_r_1 {
        background: #efefef;
        padding: 4px 6px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .ovy_r_1_1 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 210px);

          i {
            color: #e7a33a;
            cursor: pointer;
            margin-right: 10px;
          }
        }

        .ovy_r_1_2 {
          min-width: 205px;
        }
      }

      .ovy_r_2 {
        border: 1px solid #75abff;
        border-radius: 8px;
        overflow: hidden;
        padding: 10px 8px 10px 16px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 10px;
        min-height: 102px;

        &:hover {
          background: #eff7ff;
        }

        .ovy_r_2_1 {
          .ovy_r_2_1_1 {
            display: flex;

            .ovy_r_2_1_1_1 {
              min-width: 50px;
              font-size: 12px;
              border: 1px solid #75abff;
              border-radius: 4px;
              line-height: 20px;
              height: 20px;
              padding: 0 4px;
            }

            .ovy_r_2_1_1_2 {
              padding: 0 8px;
            }
          }

          .ovy_r_2_1_2 {
            display: flex;
            margin-top: 10px;

            p {
              margin-right: 20px;
            }
          }

          .ovy_r_2_1_3 {
            color: #95d373;
            margin-top: 10px;
          }
        }

        .ovy_r_2_2 {
          min-width: 222px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .ovy_r_2_2_1 {
            text-align: right;
          }
          .ovy_r_2_2_2 {
            text-align: right;
          }
        }
      }
    }
  }
}
